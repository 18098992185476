
:root {	
    --color-focus-item: #FFFF74;
    
	--wordsquare-letter-size:26px;
	--wordsquare-letter-highlighted-size:18px;
	--grid-padding:0px;
	--grid-padding-outer:100px;
	--no-border:0px;
	--thin-border:1px;
	--thick-border:10px;
	--border-radius:10px;
	--border-thin-blue:var(--thin-border) solid blue;
	--border-thin-black:var(--thin-border) solid black;
	--border-thin-red:var(--thin-border) solid red;

	--border-thick-blue:var(--thick-border) solid blue;
	--border-thick-black:var(--thick-border) solid black;
	--border-thick-red:var(--thick-border) solid red;


	--hidden-word-color:#FFFFFF;
	--hidden-wordlist-background:#A39BC2;
}


*{
	box-sizing: border-box;
}


.move-cursor{
	cursor:move;
}

body{
	background-color: aqua;
}

.header{
    width:100%;
    height: 200px;
    background-color: rgb(197, 130, 31);
}



.word-search-hiddenword-component {
	border: solid 0px #000;
	background: var(--hidden-wordlist-background);
	z-index:5;	
	height:fit-content;
	display: flex;
	flex-direction: column;
	overflow-x:hidden;
	border-radius: 10px;
	margin: 20px;
	margin-bottom: 0px;
	border: var(--border-thin-black);
  }


.word-search-hiddenword-list {
	background: var(--hidden-wordlist-background);
	z-index:5;	
	height:96%;
	width:100%;
	display: grid; /* 1 */
	grid-template-columns: repeat(auto-fill, 150px); /* 2 */	
	justify-content: space-between; /* 4 */	
	overflow-x:hidden;
	padding-left: 20px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-top: 10px;
  }


.word-search-hiddenword {
	position: relative;
	color: var(--hidden-word-color);
    font-weight: bold;
}


/* 
  So of course the grid class has the grid
  display. This element will then "host"
  individual letters that form the word square.
*/

.word-search-grid-borderbox{
	padding: var(--grid-padding-outer);
	border: var(--border-thin-black);	

	padding: 100px;
    border-radius: 15px;
	background: #FFFFD4;
	width:fit-content;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
}

.word-search-grid {
	margin: 1px;
	overflow: hidden;
	position: relative;
	background: #FFFFD4;
	display: grid;
}


.word-square-letter{
	outline: rgba(0,0,0,0.0) solid 1px;
	display:flex;
	background: transparent;	
	justify-content: center;
	align-items: center;	
	font-family: 'Courier New', Courier, monospace;
	text-transform: uppercase;	
	z-index: 4;
	user-select: none;
}

.word-square-fontA{	
	font-size: 16px;
	font-weight: bold;
}

.word-square-fontB{	
	font-size: 40px;
	font-weight: bold;
}


.word-square-letter-border{	
	position: absolute;	
	background: transparent;	
	height:var(--wordsquare-letter-highlighted-size);
	width:calc( 1 * var(--wordsquare-letter-size) );
	z-index: 3;	
}

.word-square-letter-background{	
	position: absolute;
	background: gold;
	height:var(--wordsquare-letter-highlighted-size);
	width:calc( 1 * var(--wordsquare-letter-size) );
	z-index: 2;
		

}


.word-square-letter-background-all{	
	position: absolute;
	background: white;
	height:var(--wordsquare-letter-highlighted-size);
	width:calc( 1 * var(--wordsquare-letter-size) );
	z-index: 2;	
	border-radius: var(--border-radius) 0px 0px var(--border-radius);
}





.all-borders{
	transform:rotate(45deg);
	border: 1px solid black;
}

.letter-border-all{
	border-left: var(--border-thin-blue);
	border-right: var(--border-thin-blue);
	border-top: var(--border-thin-blue);
	border-bottom: var(--border-thin-blue);
	border-radius: var(--border-radius) 0px 0px var(--border-radius);
}

.highlight-rotation-0{
	transform: rotate(0deg);
}

.highlight-rotation-45{
	transform: rotate(45deg);
	width:calc( 1.5 * var(--wordsquare-letter-size) );
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}

.highlight-rotation-90{
	transform: rotate(90deg);
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}

.highlight-rotation-135{
	transform: rotate(135deg);
	width:calc( 1.5 * var(--wordsquare-letter-size) );
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}

.highlight-rotation-180{
	transform: rotate(180deg);
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}

.highlight-rotation-225{
	transform: rotate(225deg);
	width:calc( 1.5 * var(--wordsquare-letter-size) );
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}

.highlight-rotation-270{
	transform: rotate(270deg);
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}

/* 315 in other words */
.highlight-rotation-315{
	transform: rotate(-45deg);	
	width:calc( 1.5 * var(--wordsquare-letter-size) );
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}

/* -45 in other words */
.highlight-rotation--45{
	transform: rotate(-45deg);	
	width:calc( 1.5 * var(--wordsquare-letter-size) );
	transform-origin: calc( var(--wordsquare-letter-size) / 2 );
}


.word-square-letter-background-first{	
	position: absolute;
	background: gold;
	height:var(--wordsquare-letter-highlighted-size);
	width:calc( 1.5 * var(--wordsquare-letter-size) );
	z-index: 2;	
	border-radius: var(--border-radius) 0px 0px var(--border-radius);
}

.word-square-letter-background-mid{	
	position: absolute;
	background: gold;
	height:var(--wordsquare-letter-highlighted-size);
	width:calc( 1.5 * var(--wordsquare-letter-size) );
	z-index: 2;	
	
}

.word-square-letter-background-last{	
	position: absolute;
	background: gold;
	height:var(--wordsquare-letter-highlighted-size);
	width:calc( 1 * var(--wordsquare-letter-size) );
	z-index: 2;	
	border-radius: 0px var(--border-radius) var(--border-radius) 0px;
}



.letter-border-first{
	border-left: var(--border-thin-blue);
	border-top: var(--border-thin-blue);
	border-bottom: var(--border-thin-blue);
	border-radius: var(--border-radius) 0px 0px var(--border-radius);
}
.letter-border-mid{
	border-top: var(--border-thin-blue);
	border-bottom: var(--border-thin-blue);
}
.letter-border-last{
	border-right: var(--border-thin-blue);
	border-top: var(--border-thin-blue);
	border-bottom: var(--border-thin-blue);
	border-radius: 0px var(--border-radius) var(--border-radius) 0px;	
	width:calc( 1 * var(--wordsquare-letter-size) );
}

input{
    overflow: visible;
}



.paste-input{
	background-color: #FFFF74;
	color:#FFFF74;
	text-align:center;
	font-size: xx-large;
}